import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuetify from 'vuetify' // ←追加
import 'vuetify/dist/vuetify.min.css' // ←追加
import '@fortawesome/fontawesome-free/css/all.css' 
import '@mdi/font/css/materialdesignicons.css' 

import globalMixins from './mixins/url'
Vue.mixin(globalMixins);

require('@/assets/sass/style.scss');

Vue.use(Vuetify) // ←追加
const vuetify = new Vuetify(); //←追加

import axios from 'axios' //追記
import VueAxios from 'vue-axios' //追記

import InfiniteLoading from 'vue-infinite-loading'
Vue.use(InfiniteLoading)

export default new Vuetify({
  icons: {
    iconfont: 'fa,mdi',
  },
})

;


Vue.config.productionTip = false

Vue.use(VueAxios, axios) //追記

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
