import { render, staticRenderFns } from "./Rogo13.vue?vue&type=template&id=0ae1f816&scoped=true&"
var script = {}
import style0 from "./Rogo13.vue?vue&type=style&index=0&id=0ae1f816&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ae1f816",
  null
  
)

export default component.exports