<template>
<div>
    <v-app>
        <div v-show="show">
            <Header :ScroY="scroY" v-if="!master" />
            <div id="router">
                <router-view :ScroY="scroY" />
                <div id="footer"></div>
                <div  v-show="!master" >
                <Footer/>
                </div>
            </div>
        </div>
        <Wave v-show="!show" @loaded="loaded" />

    </v-app>
</div>
</template>

<script>

import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Wave from '@/components/Wave.vue'

export default {
    data() {
        return {
            color: '#0D47A1',
            scroY: 0,
            show: false,
            master: false,
            noie:false,
        }
    },
    components: {
        Header,
        Footer,
        Wave
    },
    mounted() {



        if (location.pathname != "/") {
            this.show = true
        }

        window.addEventListener('scroll', this.handleScroll)
        for (let i = 0; i < document.getElementsByClassName('main_color').length; i++) {
            document.getElementsByClassName('main_color')[i].style.fill = this.color

        }
        for (let i = 0; i < document.getElementsByClassName('font_color').length; i++) {
            document.getElementsByClassName('font_color')[i].style.color = this.color

        }
    },
    methods: {
        handleScroll() {
            this.scroY = window.pageYOffset / 1.5;
        },
        loaded() {
            this.show = true
        },

        
    },

    
    watch:{
        $route(to) {
            if (to.path.indexOf('/master') != -1) {
                this.master = true
            }
        }

    }
}
</script>
