export default {
    mounted() {
        let title = ""

        switch (location.pathname) {
            case '/facility':
                title = '施設案内  ｜'
                break
            case '/fees':
                title = '料金  ｜'
                break
            case '/access':
                title = 'アクセス  ｜'
                break
            case '/contact':
                title = 'お問合せ･ご予約  ｜'
                break
            case '/blog':
                title = 'ブログ  ｜'
                break
            case '/faq':
                title = 'よくある質問  ｜'
                break
            default:
                title = '旧肥中海水浴･オートキャンプ場  ｜'
        }
        if(location.pathname!="/"){
        window.document.title = title + "ベイサイド104"
        }else{
            window.document.title ="ベイサイド104 | 旧肥中海水浴･オートキャンプ場"
        }

    }
}