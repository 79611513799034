<template>
<div class="w-100 inline-block overflow-hidden h-100vh">
    <div ref="rogo" class="flex-center w-100 h-100vh fixed" v-show="rogo">

        <Rogo13 />
    </div>
    <div class="wave-container">
        <vue-wavify id="wavify" class="fixed l-0 b-0" :points="5" :amplitude="15" :speed=".5" fill="url(#gradient)">
            <defs>
                <linearGradient id="gradient" gradientTransform="rotate(90)">
                    <stop offset="0%" style="stop-color:rgb(255,255,255);" />
                    <stop offset="11%" style="stop-color:rgb(50,216,217);" />
                    <stop offset="62%" style="stop-color:rgb(57,104,255);" />
                    <stop offset="100%" style="stop-color:rgb(4,81,255);" />
                </linearGradient>
            </defs>
        </vue-wavify>
    </div>
</div>
</template>

<script>
import Rogo13 from "@/components/Rogo13.vue"
import Vue from 'vue'
import VueWavify from 'vue-wavify'
Vue.use(VueWavify);

import d from "@/d"

export default {
    data() {
        return {
            rogo: false,
            rogoType: 0,
        }
    },
    components: {
        VueWavify,
        Rogo13
    },
    async mounted() {
        await d.sleep(300)
        let height = 90
        for (let i = 0; i < height; i++) {
            await d.sleep(i / 4)
            document.getElementById('wavify').style.height = i + "vh"
        }
        await d.sleep(1000)
        this.rogo = true
        for (let i = 0; i < height; i++) {
            await d.sleep(10 - i / 20)
            document.getElementById('wavify').style.height = height - i + "vh"
        }
        this.$refs.rogo.classList.add('fade-out')
        await d.sleep(1000)
        this.$emit('loaded')
    }
}
</script>

<style lang="scss" scoped>
#wavify {
    height: 0;

}
</style>
