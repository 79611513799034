import Vue from "vue";
import VueRouter from "vue-router";
import d from "@/d";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: () => import("../views/Home.vue")
    },
    {
        path: "/facility",
        name: "facility",
        component: () => import("../views/Facility.vue")
    },

    {
        path: "/fees",
        name: "fees",
        component: () => import("../views/Fees.vue")
    },
    {
        path: "/access",
        name: "access",
        component: () => import("../views/Access.vue")
    },
    {
        path: "/faq",
        name: "faq",
        component: () => import("../views/Faq.vue")
    },
    {
        path: "/contact",
        name: "contact",
        component: () => import("../views/Contact.vue")
    },
    {
        path: "/blog",
        name: "blog",
        component: () => import("../views/Blog.vue")
    },
    {
        path: "/master",
        name: "master",
        component: () => import("../views/master/Login.vue")
    },
    {
        path: "/master/fees",
        name: "masterFees",
        component: () => import("../views/master/Fees.vue")
    },
    {
        path: "/master/faq",
        name: "masterFaq",
        component: () => import("../views/master/Faq.vue")
    },
    {
        path: "/master/tearm",
        name: "masterTearm",
        component: () => import("../views/master/Tearm.vue")
    },
    {
        path: "/master/contact",
        name: "masterContact",
        component: () => import("../views/master/Contact.vue")
    },
    {
        path: "/master/facility",
        name: "masterFacility",
        component: () => import("../views/master/Facility.vue")
    },
    {
        path: "/master/blog",
        name: "masterBlog",
        component: () => import("../views/master/Blog.vue")
    },

    {
        path: "*",
        component: () => import("../views/Notfound.vue")
    }
];

const router = new VueRouter({
    mode: "history",
    base: "/",
    routes,
    async scrollBehavior() {
        document.getElementById("router").classList.remove("fade-in-router");
        document.getElementById("footerId").classList.remove("fade-in-footer");
        document.getElementById("router").style.opacity = 0;
        document.getElementById("footerId").style.opacity = 0;
        scrollTo(0, 0);
        await d.sleep(100);
        document.getElementById("router").classList.add("fade-in-router");
        document.getElementById("footerId").classList.add("fade-in-footer");
        return { x: 0, y: 0 };
    }
});

export default router;
